<template>
	<div class="page-container">
		<nav-bar title="学习中心" id="navbar" style="font-size: 18px;"></nav-bar>
		<van-notice-bar color="#11A197" background="#F0F9EB" left-icon="volume-o" :scrollable="true">
			学员您好！请点击您将要进行的培训课程类型进入学习，并祝您顺利结业！
		</van-notice-bar>
		<van-grid clickable :column-num="2">
			<van-grid-item icon="tv-o" text="驾培理论培训" url="/index.html#/login?appNo=APP01" style="color: #ff8506" />
			<van-grid-item icon="tv-o" text="从业继续教育" url="/index.html#/login?appNo=APP02" style="color: #2db1ac" />
			<van-grid-item icon="tv-o" text="企业安全培训" url="/index.html#/login?appNo=APP03" style="color: #1175fe" />
			<van-grid-item icon="tv-o" text="岗前教育培训" url="/index.html#/login?appNo=APP04" style="color: #F56C6C" />
			<van-grid-item icon="tv-o" text="违法计分教育" url="/index.html#/login?appNo=APP05" style="color: #E6A23C" />
			<van-grid-item icon="tv-o" text="两类人员培训" url="/index.html#/login?appNo=APP06" style="color: #67C23A" />
		</van-grid>
		<van-grid clickable :column-num="2">
			<van-grid-item icon="tv-o" text="从业资格证" url="/index.html#/login?appNo=APP09" style="color: #6A5ACD" />
			<van-grid-item icon="tv-o" text="公务用车安全培训" url="/index.html#/login?appNo=APP10" style="color: #6A5ACD" />
		</van-grid>
		<!-- <van-grid :column-num="1">
			<van-grid-item icon="tv-o" text="专题培训（鞍山专场请进）" url="/index.html#/?appNo=APP08" style="color: #409EFF" />
		</van-grid> -->
		<div style="margin: 45px 15px 15px 15px; font-size: 16px; background-color: #f5f5f5; padding: 10px; border-radius: 10px;">
			<span style="color:#E10900;">[温馨提醒]</span> 如果您在登录过程中遇到问题或操作失误造成无法学习，请您点击下方按钮初始化登录信息，然后重新使用正确的方式登录。
			<div style="text-align: center; margin-top: 10px;">
				<img style="height: 50px;" src="@/assets/clear.png" @click="handleInit">
			</div>
		</div>
	</div>
</template>

<script>
	import {NavBar, Toast} from "vant";

	export default {
        name: "study-center",
		components: { NavBar },
		methods: {
			handleInit() {
				sessionStorage.clear();
				localStorage.clear();
				let keys = document.cookie.match(/[^ =;]+(?=)/g);
				if(keys) {
					for(let i = keys.length; i--;)
						document.cookie = keys[i] + '=0;domain=h5.xunmeizaixian.com;expires=' + new Date(0).toUTCString() + ';path=/';
				}
				Toast.success("初始化完毕，请您重新以正确的方式登录。");
			}
		}
    }
</script>

<style scoped>
	#navbar {
		background-color: #11A197;
	}
	#navbar /deep/ .van-ellipsis {
		color: #fff !important;
	}
	#navbar /deep/ .van-nav-bar__text {
		color: #fff !important;
	}
	>>>.van-nav-bar__title {
		font-size: 18px;
	}
	>>>.van-grid-item__text {
		font-size: 16px;
	}
	>>>.van-grid-item__icon {
		font-size: 48px;
	}
	>>>.van-notice-bar__wrap {
		font-size: 16px;
	}
</style>
